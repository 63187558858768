import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/coremedia-ci/documentation-site/documentation-site/src/components/ArticleLayout/index.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <ul>
      <li parentName="ul">{`Workflows: added beyond business hours schedule slots to contact attempts`}</li>
      <li parentName="ul">{`HSM: block HSM until there’s an action from the customer in a 24h period`}</li>
      <li parentName="ul">{`Audio App: removed unleash`}</li>
      <li parentName="ul">{`Smartwindows: Updated Signature Image for Contact/Smart Windows`}</li>
      <li parentName="ul">{`Layouts: Added Terms & Conditions on Homepages.`}</li>
      <li parentName="ul">{`Bug Fix: Filter "Classification Date".`}</li>
      <li parentName="ul">{`Bug Fix: Dynamic field ID in exports created via panel.`}</li>
      <li parentName="ul">{`Bug Fix: SFTP Export Programming.`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      